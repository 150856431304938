import React, { Component } from 'react';
import Herobanner from './sections/herobanner-V1';
import Stickysection from './sections/sticky';
// import ParticlesComponent from './sections/ParticlesComponent';

export default class Landing extends Component {
  render() {
    return (
      <>
          <Herobanner/>
          {/* <Stickysection /> */}
      </>
    )
  }
}
