/* eslint-disable */
import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';

import Logo from '../../assets/images/zeebu.svg';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="headermain">
            <Container>
                <Navbar>
                    <NavbarBrand href="/"><img src={Logo} alt="Zeebu" /></NavbarBrand>
                </Navbar>
            </Container>
        </div>
    );

}
export default Header;
