/* eslint-disable */
import React, { useEffect, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, Col, Container } from 'reactstrap';
import Telegram from '../../../assets/images/landingpage/telegram.png';
import VideoBanner from '../../../assets/images/landingpage/Herobanner.mp4';
//import VideoBannerMo from '../../../assets/images/landingpage/zeebu-city-mobile.mp4';
import MobileBanner from '../../../assets/images/landingpage/mobile-herobanner.mp4';
import Btnorange from '../sections/sparkesv2or-hed';
import Btnblue from '../sections/sparkesv2blue-hed';

const Herrobanner = () => {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 1367 })
        return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
        const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1366 })
        return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 767 })
        return isMobile ? children : null
    }
    return (
        <Fragment>
            <Desktop>
                <div className="zeebuclubbanner">
                    <div className="videobanner">
                        <video className="videoembed" autoPlay muted loop playsInline>
                            <source src={VideoBanner} type="video/mp4" />
                            <source src={VideoBanner} type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    
                        <div className="videobanner-title">
                            <Container>
                                <Row>
                                    <Col md={5}>
                                        <div className="headerbannerbox">
                                            <div className="headtitlebox">
                                                <h1>Telecom Today, Rest of the world Tomorrow!</h1>
                                                <p>Experience the symphony of governance, decentralised settlements & Loyalty Rewards.</p>  
                                                <Row>
                                                    <Col md={5}>
                                                        <Btnblue />
                                                    </Col>
                                                    <Col md={5}>
                                                        <Btnorange />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </Desktop>
            <Tablet>
                <div className='tabletview'>
                    <div className="zeebuclubbanner">
                            <div className="videobanner">
                                <video className="videoembed" autoPlay muted loop playsInline>
                                    <source src={MobileBanner} type="video/mp4" />
                                    <source src={MobileBanner} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="videobanner-title">
                                    <Container>
                                        <Row>
                                            <Col md={6}>
                                                <div className="headerbannerbox">
                                                    <div className="headtitlebox">
                                                        <h1>Telecom Today, Rest of the world Tomorrow!</h1>
                                                        <p>Experience the symphony of governance, decentralised settlements & Loyalty Rewards.</p>
                                                        <Row>
                                                            <Col lg={5} md={6}>
                                                                <Btnblue />
                                                            </Col>
                                                            <Col lg={5} md={6}>
                                                                <Btnorange />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                    </div>
                </div>
            </Tablet>
            <Mobile>
                <div className='mobile-herobanner full-screen-section'>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h1>Telecom Today, Rest of the world Tomorrow!</h1>
                                <p>Experience the symphony of governance, decentralised settlements & Loyalty Rewards.</p>
                                <Row>
                                    <Col xs={6}>
                                        <Btnblue />
                                    </Col>
                                    <Col xs={6}>
                                        <Btnorange />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    <div className='mobileherovideo'>
                        <video autoPlay muted loop playsInline>
                            <source src={MobileBanner} type="video/mp4" />
                            <source src={MobileBanner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </Mobile>
        </Fragment>
    );
}

export default Herrobanner;
