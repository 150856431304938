import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from "history";
import {
  Route,
  Routes,
  BrowserRouter 
} from "react-router-dom";
import Header from "./components/header/header.jsx";
import Footer from "./components/footer/footer.jsx";
import Landing from "./pages/landing/index.jsx";
import Aboutus from "./pages/aboutus/aboutus.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

var hist = createBrowserHistory();
root.render(
  <BrowserRouter history={hist}>
      <Header />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/aboutus" element={<Aboutus />} />
        </Routes>
      {/* <Footer /> */}
  </BrowserRouter>
);

