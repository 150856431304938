import React, { useEffect, useRef,Fragment } from 'react';
import { Link } from 'react-router-dom';


function ParticleSystem() {
  const canvasRef = useRef(null);
  const spotsRef = useRef([]);
  const hueRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = 205;
    canvas.height = 60;

    const createParticles = (x, y) => {
      for (let i = 0; i < 1; i++) {
        spotsRef.current.push(new Particle(x, y, ctx));
      }
    };

    class Particle {
      constructor(x, y, ctx) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 1 + 2;
        this.speedX = (Math.random() - 0.5) * 2;
        this.speedY = (Math.random() - 0.5) * 2;
        // this.color = `hsl(${hueRef.current}, 100%, 50%)`;
        this.color = `#FFF`;
        this.ctx = ctx;
      }

      draw() {
        this.ctx.beginPath();
        this.ctx.fillStyle = this.color;
        this.ctx.arc(this.x, this.y, this.size, 0, 70 * Math.PI);
        this.ctx.fill();
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.size > 0.8) this.size -= 0.1;
      }
    }

    const handleParticles = () => {
      spotsRef.current.forEach((particle, index) => {
        particle.update();
        particle.draw();
        if (particle.size <= 0.1) {
          spotsRef.current.splice(index, 1);
        }
      });
      hueRef.current++;
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      handleParticles();
      requestAnimationFrame(animate);
    };

    animate();

    const handleMouseMove = (event) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      createParticles(x, y);
    };

    canvas.addEventListener('mousemove', handleMouseMove);

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Fragment>
      <Link to="https://psp.zeebu.com/register-governance?ref=Deployer" target="_blank" className='telegrambtnv2'>
        <div className='buttonmain'>
          <canvas ref={canvasRef}></canvas>   
          <div className='telbtn'>Become Deployer</div>                       
        </div>
      </Link>
    </Fragment>
  );
}

export default ParticleSystem;
